/* eslint-disable no-unused-vars */
import { ref, reactive } from '@vue/composition-api'

export default function prayers() {
  const cityData = ref({
    city: null,
    country: null,
    from: '2021-07-01',
    to: '2021-07-07',
    timezone: null,
    calculation_method: null,
    madhab_method: null,
    high_latitude_rule: null,
    polar_circle_resolution: null,
    fajr_angle: null,
    isha_angle: null,
    adjustments: null,
    lat: null,
    long: null,
  })

  return {
    cityData,
  }
}
