<template>
  <b-row>
    <!-- City -->
    <b-col cols="12">
      <b-form-group
        label="city"
        label-for="search"
      >
        <b-form-input
          id="city"
          v-model="cityData.city"
          placeholder="City"
        />
      </b-form-group>
    </b-col>

    <!-- Country -->
    <b-col cols="12">
      <b-form-group
        label="Country"
        label-for="country"
      >
        <b-form-input
          id="country"
          v-model="cityData.country"
          placeholder="Country"
        />
      </b-form-group>
    </b-col>

    <!-- From -->
    <b-col cols="12">
      <b-form-group
        label="From"
        label-for="search"
      >
        <b-form-datepicker
          id="from"
          v-model="cityData.from"
          class="mb-1"
        />
      </b-form-group>
    </b-col>

    <!-- To -->
    <b-col cols="12">
      <b-form-group
        label="To"
        label-for="search"
      >
        <b-form-datepicker
          id="to"
          v-model="cityData.to"
          class="mb-1"
        />
      </b-form-group>
    </b-col>

    <!-- Advanced -->
    <b-col cols="12">
      <b-form-group
        label-for="submit"
      >
        <b-button
          variant="flat-primary"
          @click="advanced = !advanced"
        >
          <feather-icon icon="SettingsIcon" /> Advanced Options
        </b-button>
      </b-form-group>
    </b-col>
    <b-row v-if="advanced">

      <!-- Timezone -->
      <b-col cols="12">
        <b-form-group
          label="Timezone"
          label-for="timezone"
        >
          <b-form-input
            id="timezone"
            v-model="cityData.timezone"
            placeholder="Timezone"
          />
        </b-form-group>
      </b-col>

      <!-- Calculation Method -->
      <b-col cols="12">
        <b-form-group
          label="Calculation Method"
          label-for="calcmethod"
        >
          <b-form-select
            id="calcmethod"
            v-model="cityData.calculation_method"
            :options="settings.calculation_methods"
            value-field="value"
            text-field="text"
          />
        </b-form-group>
      </b-col>

      <!-- Madhab Method -->
      <b-col cols="12">
        <b-form-group
          label="Madhab Method"
          label-for="madhmethod"
        >
          <b-form-select
            id="madhmethod"
            v-model="cityData.madhab_method"
            :options="settings.madhab_methods"
            value-field="value"
            text-field="text"
          />
        </b-form-group>
      </b-col>

      <!-- High Latitude Rule Methods -->
      <b-col cols="12">
        <b-form-group
          label="High Latitude Rule Methods"
          label-for="HLRM"
        >
          <b-form-select
            id="HLRM"
            v-model="cityData.high_latitude_rule"
            :options="settings.high_latitude_rule_methods"
            value-field="value"
            text-field="text"
          />
        </b-form-group>
      </b-col>

      <!-- Polar Circle Resolution Methods -->
      <b-col cols="12">
        <b-form-group
          label="Polar Circle Resolution Methods"
          label-for="PCRM"
        >
          <b-form-select
            id="PCRM"
            v-model="cityData.polar_circle_resolution"
            :options="settings.polar_circle_resolution_methods"
            value-field="value"
            text-field="text"
          />
        </b-form-group>
      </b-col>

      <!-- Fajr Angle -->
      <b-col cols="12">
        <b-form-group
          label="Fajr Angle"
          label-for="fajrangel"
        >
          <b-form-select
            id="fajrangel"
            v-model="cityData.fajr_angle"
            :options="settings.fajr_angles"
            value-field="value"
            text-field="text"
          />
        </b-form-group>
      </b-col>

      <!-- Isha Angle -->
      <b-col cols="12">
        <b-form-group
          label="Isha Angle"
          label-for="ishaangel"
        >
          <b-form-select
            id="ishaangel"
            v-model="cityData.isha_angle"
            :options="settings.isha_angles"
            value-field="value"
            text-field="text"
          />
        </b-form-group>
      </b-col>

      <!-- Adjustments -->
      <b-col cols="12">
        <b-form-group
          label="Adjustments"
          label-for="adjustments"
        >
          <b-form-select
            id="adjustments"
            v-model="cityData.adjustments"
            :options="settings.adjustment"
            value-field="value"
            text-field="text"
          />
        </b-form-group>
      </b-col>

      <!-- Lat -->
      <b-col cols="12">
        <b-form-group
          label="Lat"
          label-for="lat"
        >
          <b-form-input
            id="lat"
            v-model="cityData.lat"
            placeholder="Lat"
          />
        </b-form-group>
      </b-col>

      <!-- Long -->
      <b-col cols="12">
        <b-form-group
          label="Long"
          label-for="long"
        >
          <b-form-input
            id="long"
            v-model="cityData.long"
            placeholder="Long"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Search-BTN -->
    <b-col cols="12">
      <b-form-group
        label-for="submit"
      >
        <b-button
          variant="primary"
          @click="getPrayersByCity()"
        >
          Search
        </b-button>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton, BFormInput, BFormGroup, BFormDatepicker, BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BButton, BFormInput, BFormGroup, BFormDatepicker, BFormSelect,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['cityData', 'getPrayersByCity', 'advanced', 'settings'],
  created() {
    this.getPrayersByCity()
  },
}
</script>

<style>

</style>
