<template>
  <div id="user-profile">
    <user-header />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <prayer-nav />
      </div>
    </b-card>
    <b-row class="mt-2">
      <b-col
        md="6"
        lg="4"
      >
        <b-card>
          <div>
            <basic-search
              :city-data="cityData"
              :get-prayers-by-city="getPrayersByCity"
              :advanced="advanced"
              :settings="settings"
            />
          </div>
        </b-card>
      </b-col>
      <b-col
        md="6"
        lg="8"
      >
        <b-card>
          <table id="customers">
            <thead>
              <tr id="mm">
                <th class="temp" />
                <th>Fajr</th>
                <th>Sunrise</th>
                <th>Dhuhur</th>
                <th>Asr</th>
                <th>Maghrib</th>
                <th>Isha</th>
              </tr>
            </thead>
            <tbody
              v-for="(day, index) in dayResults"
              :key="index"
            >
              <tr
                class="gray"
              >
                <td class="day">
                  {{ day.day }}
                </td>
                <td
                  v-for="(k, v) in day.data"
                  :key="v"
                  class="text-center"
                >
                  {{ k.prayer }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import store from '@/store'
import {
  // eslint-disable-next-line no-unused-vars
  onUnmounted, ref, computed, reactive,
} from '@vue/composition-api'
import prayersModule from './PrayersModule'
import BasicSearch from './BasicSearch.vue'
import UserHeader from './UserHeader.vue'
import prayerNav from './Nav.vue'
import prayer from './prayers'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BasicSearch,
    UserHeader,
    prayerNav,
  },
  data() {
    return {
      dayResults: [],
      advanced: false,
      settings: [],
    }
  },
  setup() {
    const { cityData } = prayer()
    const PRAYERS_DATA_MODULE = 'prayers'
    // const stor = useStore

    // Register module
    if (!store.hasModule(PRAYERS_DATA_MODULE)) store.registerModule(PRAYERS_DATA_MODULE, prayersModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRAYERS_DATA_MODULE)) store.unregisterModule(PRAYERS_DATA_MODULE)
    })

    // const dayResults = computed(() => store.state.prayers.dayResults)

    // function getprayer() {
    //   store.dispatch('prayers/getPrayersByCity', cityData)
    // }

    // store.dispatch('prayers-data/getPrayersByCity')

    return {
      cityData,
      // dayResults,
      // getprayer,
    }
  },
  created() {
    this.getPrayersByCity()
    this.getSetting()
  },
  methods: {
    getPrayersByCity() {
      axios.post('https://gateway.muslimdo.com/api/prayers/prayers-by-city', this.cityData).then(res => {
        console.log(res.data.data.prayers)
        this.dayResults = res.data.data.prayers
      })
    },
    async getSetting() {
      const response = await axios.post('https://gateway.muslimdo.com/api/prayers/settings')
      this.settings = response.data.data
      console.log(response.data.data)
    },
  },
}
</script>

<style lang="scss" >
.form-control {
    border: 1px solid #d8d6de;
}

#customers {
  border-collapse: separate;
  width: 100%;
  border-spacing: 0px 12px;
}

#customers td {
  border: 1px solid #ebedef;
  padding: 15px;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  border: 1px solid #ddd;
  text-align: left;
  background-color: #00A5AF;
  color: white;
  padding: 12px 26px;
}

@import '@core/scss/vue/pages/page-profile.scss';

</style>
