// eslint-disable-next-line no-unused-vars
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    dayResults: [],
  },
  getters: {},
  mutations: {
    GET_PRAYERS(state, data) {
      state.dayResults = data
    },
  },
  actions: {
    getPrayersByCity({ commit }, { data }) {
      axios.post('https://gateway.muslimdo.com/api/prayers/prayers-by-city', data).then(res => {
        commit('GET_PRAYERS', res.data.data.prayers)
      })
    },
  },
}
